// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useIonViewDidEnter,
    useIonViewWillLeave,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import Slider from 'react-slick'

// data
import {
    defaultReduxState,
    groupingIdsSite,
    reduxModalErrorEventHandlerSite,
} from 'data'

// serializers
import {
    CustomCSSProperties,
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// services
import {
    getStylesNew,
} from 'services'

import * as templateBlock from './index'

// helpers
function CustomNextArrow(props: any) {
    const { className, style, onClick, styles, reduxModalSite } = props
    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={onClick}
        >
            <i
                className={`t-790-arrow mo-new-icon-chevron-right-solid ${reduxModalSite.deviceType}`}
                style={styles}
            />
        </div>
    )
}

function CustomPrevArrow(props: any) {
    const { className, style, onClick, styles, reduxModalSite } = props
    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={onClick}
        >
            <i
                className={`t-790-arrow mo-new-icon-chevron-left-solid ${reduxModalSite.deviceType}`}
                style={styles}
            />
        </div>
    )
}

// props
type TemplateBlock790SiteProps = {
    blockId: string
    content: PortfolioPageContentListSiteSerializer[]
    detailId: string | undefined
    devId: string | undefined
    inListIndex?: number
    isEditHovered: boolean
    isInComponent?: boolean
    isInDetailPage?: boolean
    isInVideoBlock?: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    pageSlug: string | undefined
    parentArray?: number[]
    parentDropdownId?: number
    parentStyles?: any
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock790Site: React.FC<TemplateBlock790SiteProps> = React.memo(({
    blockId,
    content,
    detailId,
    devId,
    inListIndex,
    isEditHovered,
    isInComponent,
    isInDetailPage,
    isInVideoBlock,
    mainParentId,
    object,
    pageSlug,
    parentArray,
    parentDropdownId,
    parentStyles,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const reduxCacheSiteportfolioid = useSelector((state: defaultReduxState) => state.reduxCacheSite.portfolio?.id)
    const reduxFormSitehoveredBlockId = useSelector((state: defaultReduxState) => state.reduxFormSite.hoveredBlockId)
    const reduxFormSiteparentContent = useSelector((state: defaultReduxState) => state.reduxFormSite.parentContent)
    const reduxFormSiteeditAutoSave = useSelector((state: defaultReduxState) => state.reduxFormSite.editAutoSave)
    const reduxFormSiteeditAutoSaved = useSelector((state: defaultReduxState) => state.reduxFormSite.editAutoSaved)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    // const [items, setItems] = useState<any[]>(content[0]?.content_ids || [])
    const [stylesNew, setStylesNew] = useState<{
        slickStyles: CustomCSSProperties | undefined,
        slickSettings: any,
    } | undefined>(applyStyles())
    const [visible, setIsVisible] = useState<boolean>(reduxCacheSiteportfolioid === 344 ? false : true)

    let wto: any
    useEffect(() => {
        if (reduxCacheSiteportfolioid !== 344) return
        clearTimeout(wto)
        wto = setTimeout(() => {
            setIsVisible(true)
        }, 300)
        return () => {
            clearTimeout(wto)
        }
    }, [])

    // useEffect(() => {
    //     setItems(content[0]?.content_ids || [])
    // }, [
    //     content[0]?.content_ids,
    // ])

    useEffect(() => {
        setStylesNew(applyStyles())
    }, [
        object.id,
        reduxModalSite,
        styles,
        stylesEdit,
    ])

    let wto2: any
    useIonViewDidEnter(() => {
        if (reduxCacheSiteportfolioid !== 344) return
        clearTimeout(wto2)
        wto2 = setTimeout(() => {
            setIsVisible(true)
        }, 300)
    })

    useIonViewWillLeave(() => {
        if (reduxCacheSiteportfolioid !== 344) return
        setIsVisible(false)
    })

    function applyStyles() {
        try {
            const slickStyles = getStylesNew(reduxModalSite, styles, stylesEdit)

            const arrows = slickStyles?.slickArrows
            const autoplay = slickStyles?.slickAutoplay
            const centerMode = slickStyles?.slickCenterMode
            const dots = slickStyles?.slickDots
            const fade = slickStyles?.slickFade
            const infinite = slickStyles?.slickInfinite
            const variableWidth = slickStyles?.slickVariableWidth
            const settings = {
                arrows: arrows ? ((arrows === 'true') ? true : false) : true,
                autoplay: autoplay ? ((autoplay === 'true') ? true : false) : false,
                autoplaySpeed: Number(slickStyles?.slickAutoplaySpeed) || 3000,
                centerMode: centerMode ? ((centerMode === 'true') ? true : false) : false,
                dots: dots ? ((dots === 'true') ? true : false) : false,
                draggable: false,
                fade: fade ? ((fade === 'true') ? true : false) : false,
                infinite: infinite ? ((infinite === 'true') ? true : false) : true,
                nextArrow: <CustomNextArrow reduxModalSite={reduxModalSite} styles={slickStyles} />,
                pauseOnHover: false,
                prevArrow: <CustomPrevArrow reduxModalSite={reduxModalSite} styles={slickStyles} />,
                // rtl: reduxModalSite.languageId === 19 ? true : undefined,
                slidesToScroll: Number(slickStyles?.slickSlidesToScroll) || 1,
                slidesToShow: Number(slickStyles?.slickSlidesToShow) || 1,
                speed: Number(slickStyles?.slickSpeed) || 500,
                variableWidth: variableWidth ? ((variableWidth === 'true') ? true : false) : false,
                // lazyLoad: true,
            }
            return {
                slickStyles: slickStyles,
                slickSettings: settings,
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock790Site',
                'applyStyles',
            ))
        }
    }

    // function getMoreData(oldIndex: number, newIndex: number) {  // TODO, needs lazy load, but sliding not smooth
    //     try {
    //         console.log(oldIndex, newIndex);
    //         console.log(object.is_list);
    //         console.log(items.length);
    //         if ((items.length - newIndex) <= 19) {
    //             console.log('loadmore');
    //             console.log([...items, ...items]);
    //             setItems([...items, ...items])
    //         }
    //     } catch (error) {
    //         dispatch(reduxModalErrorEventHandlerSite(
    //             error,
    //             'TemplateBlock790Site',
    //             'applyStyles',
    //         ))
    //     }
    // }

    if (object.is_list) {
        const contentIds = content[0]?.content_ids || []
        return (
            <div
                id={blockId}
                className={`template-block-790${stylesNew?.slickSettings?.variableWidth ? ' variable-width' : ''}${visible ? ' visible' : ''}${reduxFormSiteparentContent === object.id ? ' active' : ''}${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
                style={stylesNew?.slickStyles}
            >
                <Slider
                    {...stylesNew?.slickSettings}
                // beforeChange={(oldIndex: number, newIndex: number) => getMoreData(oldIndex, newIndex)}
                >
                    {contentIds.map((val2: any, i: number) => {
                        return (
                            content.filter(obj => obj.active === true).map((val) => {
                                // @ts-ignore
                                const Component = templateBlock[`TemplateBlock${val.layout}Site`]
                                const contentBlockId = `content-new-child-${val.id}-layout-${val.layout}`
                                return (
                                    <Component
                                        key={val.id}
                                        blockId={contentBlockId}
                                        content={groupingIdsSite.includes(val.layout!) ? val.content : val.content[i]}
                                        contentArray={contentIds}
                                        detailId={detailId}
                                        devId={devId}
                                        disableInfiniteScroll={true}
                                        hasMore={false}
                                        inListIndex={i}
                                        isInComponent={isInComponent || ['f', 'n'].includes(object.layout_type!)}
                                        isEditHovered={reduxFormSitehoveredBlockId === val.id}
                                        isInDetailPage={isInDetailPage}
                                        isInVideoBlock={isInVideoBlock || object.layout_type === 'v'}
                                        mainParentId={mainParentId || object.id}
                                        object={val}
                                        onSearchNext={undefined}
                                        pageSlug={pageSlug}
                                        parentArray={parentArray ? [object.id, ...parentArray] : [object.id]}
                                        parentDropdownId={parentDropdownId}
                                        parentStyles={object.styles}
                                        styles={val.styles}
                                        stylesEdit={reduxFormSiteeditAutoSave[val.id!]?.styles || reduxFormSiteeditAutoSaved[val.id!]?.styles}
                                    />
                                )
                            })
                        )
                    })}
                </Slider>
            </div>
        )
    }

    return (
        <div
            id={blockId}
            className={`template-block-790${visible ? ' visible' : ''}${reduxFormSiteparentContent === object.id ? ' active' : ''}${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
            style={stylesNew?.slickStyles}
        >
            <Slider {...stylesNew?.slickSettings}>
                {content?.filter(obj => obj.active === true).map((val) => {
                    // @ts-ignore
                    const Component = templateBlock[`TemplateBlock${val.layout}Site`]
                    const contentBlockId = `content-new-child-${val.id}-layout-${val.layout}`
                    return (
                        <Component
                            key={val.id}
                            blockId={contentBlockId}
                            content={(!groupingIdsSite.includes(val.layout!) && inListIndex !== undefined) ? val.content[inListIndex] : val.content}
                            detailId={detailId}
                            devId={devId}
                            disableInfiniteScroll={true}
                            hasMore={false}
                            inListIndex={groupingIdsSite.includes(val.layout!) ? inListIndex : undefined}
                            isEditHovered={reduxFormSitehoveredBlockId === val.id}
                            isInDetailPage={isInDetailPage}
                            mainParentId={mainParentId || object.id}
                            object={val}
                            onSearchNext={undefined}
                            pageSlug={pageSlug}
                            parentArray={parentArray ? [object.id, ...parentArray] : [object.id]}
                            parentStyles={object.styles}
                            styles={val.styles}
                            stylesEdit={reduxFormSiteeditAutoSave[val.id!]?.styles || reduxFormSiteeditAutoSaved[val.id!]?.styles}
                        />
                    )
                })}
            </Slider>
        </div>
    )
})
