// react components
import React from 'react'

// data
import {
    playerTrackTypeSite,
    reduxAuthStateSite,
    reduxModalLightBoxShowHideSite,
    reduxModalSliderDetailShowHideSite,
    reduxModalSliderGetDataSite,
    reduxModalStateSite,
    reduxPlayerOnPlayPauseInlineSite,
    reduxPlayerSetCurrentTrackSite,
} from 'data'

// services
import {
    parseQuerySite,
    stringifyQuery,
} from 'services'

// main
export function onClickIsModalSite(
    dispatch: React.Dispatch<any>,
    reduxAuth: reduxAuthStateSite,
    reduxModalSite: reduxModalStateSite,
    object: playerTrackTypeSite,
    contentObject: { action?: string, data_json?: any },
    content: any[],
    isInVideoBlock?: boolean,
    history?: any,
    to?: string,
    href?: boolean,
    noTargetBlank?: boolean,
) {

    if (!object.id) return

    if (contentObject.action && ['play'].includes(contentObject.action)) {
        if (object.is_playable) {
            if (isInVideoBlock) {
                dispatch(reduxPlayerOnPlayPauseInlineSite(object.id))
            } else {
                dispatch(reduxPlayerSetCurrentTrackSite(
                    object,
                    reduxAuth,
                    reduxModalSite,
                    dispatch,
                    'full',
                    'playerOrigin',
                    content,
                ))
            }
        } else if (object.absolute_site_url?.startsWith('asset-url/')) {
            window.open(object.link_url, noTargetBlank ? '_self' : '_blank')
        } else {
            dispatch(reduxModalLightBoxShowHideSite(
                true,
                object.id,
                content,
                contentObject.data_json?.allowDownload,
            ))
        }
        return
    }

    if (contentObject.action === 'modal_link') {
        dispatch(reduxModalSliderGetDataSite(
            content.length,
            false,
            false,
            content,
            object.id,
        ))

        let listUrl = `${window.location.pathname}`  // Keep quotes

        const parsed = parseQuerySite(window.location.search)
        delete parsed.modal_page
        delete parsed.modal_detail_id

        if (parsed && Object.keys(parsed).length > 0 && parsed.constructor === Object) {
            listUrl += `?${stringifyQuery(parsed)}`
        }

        dispatch(reduxModalSliderDetailShowHideSite(
            true,
            listUrl,
            object.id,
        ))
    }

    if (href && to) {
        window.open(to, noTargetBlank ? '_self' : '_blank')
        return
    }

    if (history && to) history.push(to)
}
