// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import VisibilitySensor from 'react-visibility-sensor'

// components
import {
    LinkHelperSite,
} from 'components'

// data
import {
    defaultReduxState,
    languageIdObjectSite,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    EditBlockSite,
} from 'pages'

// serializers
import {
    CustomCSSProperties,
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
    Template815SiteSerializer,
} from 'serializers/site'

// services
import {
    getStylesNew,
    onClickIsModalSite,
} from 'services'

// props
type TemplateBlock815SiteProps = {
    blockId: string
    content: Template815SiteSerializer | undefined
    contentArray?: Template815SiteSerializer[] | undefined
    isEditHovered: boolean
    isInComponent?: boolean
    isInVideoBlock?: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    parentArray?: number[]
    parentStyles?: any
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock815Site: React.FC<TemplateBlock815SiteProps> = React.memo(({
    blockId,
    content,
    contentArray,
    isEditHovered,
    isInComponent,
    isInVideoBlock,
    mainParentId,
    object,
    parentArray,
    parentStyles,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    const [disableIncrease, setDisableIncrease] = useState(false)
    const [increasingNumber, setInscreasingNumber] = useState<number>(0)
    const [sensorIsActive, setSensorIsActive] = useState(true)
    const [showText, setShowText] = useState(false)
    const [stylesNew, setStylesNew] = useState<{
        speed: string,
        styles: CustomCSSProperties | undefined,
    } | undefined>(applyStyles())

    let wto: any
    useEffect(() => {
        clearTimeout(wto)
        wto = setTimeout(() => {
            setShowText(true)
        }, 100)
        return () => {
            clearTimeout(wto)
        }
    }, [])

    useEffect(() => {
        setStylesNew(applyStyles())
    }, [
        object.id,
        reduxModalSite,
        styles,
        stylesEdit,
    ])

    function applyStyles() {
        try {
            const aStyles = getStylesNew(reduxModalSite, styles, stylesEdit)
            return {
                speed: aStyles?.speed || '1000',
                styles: aStyles,
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock815HelperSite',
                'applyStyles',
            ))
        }
    }

    function onLinkClick() {
        try {
            if (content && contentArray) {
                onClickIsModalSite(dispatch, reduxAuth, reduxModalSite, content, object, contentArray, isInVideoBlock)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock815HelperSite',
                'onLinkClick',
            ))
        }
    }

    function onChange(isVisible: boolean) {
        if (isVisible) {
            setSensorIsActive(false)
            increaseToValueInXSeconds(0, Number(content?.text), Number(stylesNew?.speed))
        }
    }

    function increaseToValueInXSeconds(number: number, targetValue: number, milliseconds: number) {
        const incrementAmount = (targetValue - number) / (milliseconds / 50) // Increment amount every 50 milliseconds
        const intervalId = setInterval(() => {
            number += incrementAmount
            if (number >= targetValue) {
                clearInterval(intervalId)
                setDisableIncrease(true)
            } else {
                setInscreasingNumber(number)
            }
        }, 50)
    }

    if (!showText) return null

    return (
        <VisibilitySensor
            active={sensorIsActive}
            intervalDelay={100}
            onChange={onChange}
        >
            <LinkHelperSite
                id={blockId}
                action={object.action}
                className={`template-block-815${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
                onClick={onLinkClick}
                style={stylesNew?.styles}
                textTag='span'
                to={content?.absolute_site_url}
            >
                {/* @ts-ignore */}
                {content?.text_before}{disableIncrease ? Number(content?.text).toLocaleString(languageIdObjectSite[reduxModalSite.languageId]) : increasingNumber.toFixed()}{content?.text_after}
                <EditBlockSite
                    isInComponent={isInComponent}
                    mainParentId={mainParentId || object.id!}
                    object={object}
                    parentArray={parentArray}
                    parentStyles={parentStyles}
                />
            </LinkHelperSite>
        </VisibilitySensor>
    )
})
